import React from 'react';

import './footer.css';

/**
 * Footer Functional Component.
 */
export default function Footer() {
    return (
        <footer className="footer">
            <div className="wrapper">
                © {new Date().getFullYear()} Elodie Arete. UI/UX Designer.
            </div>
        </footer>
    );
}

Footer.displayName = 'Footer';
