import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import Footer from 'components/footer';

import 'suitcss-base';
import './layout.css';

function hasTouch() {
    return (
        (typeof window !== `undefined` &&
            'ontouchstart' in document.documentElement) ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
}

/**
 * Layout Functional Component.
 */
export default function Layout({ children }) {
    const [ready, setReady] = useState(false);
    const [touch, setTouch] = useState(false);

    useEffect(() => {
        setReady(true);
        setTouch(hasTouch());
    }, []);

    return (
        <>
            <Helmet
                title="Elodie Arete"
                meta={[
                    {
                        name: 'description',
                        content:
                            "Ce book en ligne est une sélection de mes projets de designer interactif. Il regroupe mes projets de graphisme print ou web, mes projets de motion design ou mes projets d'intégration.",
                    },
                    {
                        name: 'keywords',
                        content:
                            'book, web design, interactif, graphisme multimédia, site web, css3, jQuery, JavaScript, responsive design, intégration, illustration, html5, css3, ux, ui',
                    },
                    {
                        name: 'author',
                        content: 'Elodie Arete',
                    },
                ]}
                link={[
                    {
                        rel: 'shortcut icon',
                        type: 'image/png',
                        href: withPrefix('/favicon.ico'),
                    },
                    {
                        rel: 'stylesheet',
                        href:
                            'https://fonts.googleapis.com/css?family=Lato:300,400,700,900|Montserrat:400,600',
                    },
                ]}
            />

            <div
                className={`layout ${ready ? 'layout--ready' : ''} ${
                    touch ? 'layout--touch' : ''
                }`.trim()}
            >
                { children }
                <Footer />
            </div>
        </>
    );
}

Layout.displayName = 'Layout';
